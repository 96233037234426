import $ from 'jquery';
import exportBtns from './util/export-buttons';
import { serverUrl, statusInvoiceColors } from '@/global/variables';

// Status Options
export const statusOptions = [
	{ value: null, text: 'Status' },
	{ value: 'PAID', text: 'Paid' },
	{ value: 'UNPAID', text: 'Un-paid' },
	{ value: 'DEDUCT', text: 'Deduct' }
];

// INVOICES TABLE -- ADMIN
// Setup Table
export const setupTable = ({ url, forAdmin = false, showUser = false }) => {
	return url && $('#vue-datatable').DataTable({
		processing: true,
		serverSide: true,
		ajax: {
			url: serverUrl(url),
			beforeSend: function(request) {
				request.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`);
			}
		},
		buttons: [ ...exportBtns({ 2: 'Status' }), 'columnsToggle' ],
		dom: 'Bfrtip',
		pageLength: 50,
		columns: [
			{ data: 'id' },
			{ data: 'amount' },
			{ data: 'status' },
			{ data: 'created_at' },
			{ data: 'by' },
			showUser ? { data: 'user.username' } : null
		].filter((c) => c),
		columnDefs: [
			{
				targets: 0,
				orderable: true
			},
			{
				targets: 1,
				orderable: true
			},
			{
				targets: 2,
				orderable: true,
				width: '150px',
				render: function(data, type, row) {
					const classesBadge = [
						'badge',
						`badge-${statusInvoiceColors[data]}`,
						'badge-pill',
						data === 'UNPAID' && forAdmin && 'unpaid-btn'
					].join(' ');
					const unpaidBtn = (content) => `<div onclick="toPaid(${row.id})">${content}</div>`;
					const status = `<span class='${classesBadge}'>` + data + '</span>';
					return data === 'UNPAID' && forAdmin ? unpaidBtn(status) : status;
				}
			},
			{
				targets: 3,
				orderable: true,
				className: 'tablet-l desktop'
			},
			{
				targets: 4,
				orderable: true,
				className: 'tablet-l desktop'
			}
		],
		responsive: true,
		order: [ [ 0, 'desc' ] ],
		autoWidth: false
	});
};
