<template>
  <!-- Header Actions -->
  <div class="actions-table">
    <!-- Left side -->
    <div class="left-actions">
      <!-- Export -->
      <b-dropdown text="Export" variant="primary">
        <b-dropdown-item @click="doAction('buttons-copy')">
          Copy
        </b-dropdown-item>
        <b-dropdown-item @click="doAction('buttons-csv')">
          CSV
        </b-dropdown-item>
        <b-dropdown-item @click="doAction('buttons-excel')">
          Excel
        </b-dropdown-item>
        <b-dropdown-item @click="doAction('buttons-print')">
          Print
        </b-dropdown-item>
      </b-dropdown>
      <!-- Select btn -->
      <b-button
        v-if="selectBtn"
        @click="$emit('selectAction')"
        :variant="selectBtn.color"
        :disabled="selectBtn.loading"
      >
        <b-spinner v-if="selectBtn.loading" small></b-spinner>
        {{ selectBtn.text }}
      </b-button>
      <slot name="head-actions" />
    </div>
    <!-- Right side -->
    <div class="d-flex right-side">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="search"
          @input="(v) => table.search(v).draw()"
          placeholder="Search"
        />
      </b-input-group>
      <!-- Filter -->
      <b-button
        v-if="filter"
        :disabled="loadingFilter"
        v-b-modal.filter-modal
        variant="flat-success"
        class="ml-1 btn-icon"
        v-b-tooltip.hover.bottom
        title="Filter"
      >
        <feather-icon v-if="!loadingFilter" icon="FilterIcon" />
        <b-spinner v-else small />
      </b-button>
      <!-- Refresh -->
      <b-button
        @click="$emit('refresh')"
        v-if="refresh"
        variant="flat-success"
        class="ml-1 btn-icon"
        v-b-tooltip.hover.bottom
        title="Refresh"
      >
        <feather-icon icon="RefreshCcwIcon" />
      </b-button>
      <FilterDailog
        v-if="filter"
        :data="filter"
        @apply="applyFilter"
        @reset="clearFilter"
      />
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BButton,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  VBTooltip,
} from "bootstrap-vue";
import FilterDailog from "../FilterDataTable.vue";
export default {
  props: ["refresh", "filter", "table", "selectBtn"],
  data: () => ({
    search: "",
    loadingFilter: false,
  }),
  methods: {
    doAction(action) {
      this.$emit("export", action);
    },
    // Apply filters
    async applyFilter(data) {
      this.loadingFilter = true;
      this.filter.onSubmit &&
        (await this.filter.onSubmit(data, this.table, this.searching));
      this.loadingFilter = false;
    },
    // Clear Filter
    async clearFilter() {
      this.loadingFilter = true;
      this.filter.reset && (await this.filter.reset(this.table));
      this.loadingFilter = false;
    },
  },
  directives: { "b-tooltip": VBTooltip },
  components: {
    FilterDailog,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
};
</script>

<style>
</style>