<template>
  <b-card :title="title">
    <div class="datatable-comp">
      <HeaderActions
        :table="table"
        :filter="advancedFilter"
        :refresh="allowRefresh"
        :selectBtn="selectBtn"
        @export="doAction"
        @selectAction="$emit('handleSelect', table.rows('.selected').data())"
        @refresh="refresh"
      >
        <template #head-actions>
          <div>
            <slot name="head-actions" />
          </div>
        </template>
      </HeaderActions>
      <!-- Table -->
      <div class="table-wrapper-datatable">
        <div class="expand-all-btn" v-if="table && !notExpandAll">
          <feather-icon
            @click="expandAll()"
            :class="collapse ? 'minus-icon' : 'plus-icon'"
            :icon="collapse ? 'MinusIcon' : 'PlusIcon'"
          />
        </div>
        <table
          :id="compID || 'vue-datatable'"
          :class="customClass || 'display responsive compact'"
          width="100%"
        >
          <thead ref="hello">
            <slot name="thead" />
          </thead>
          <slot name="body" />
        </table>
      </div>
      <!-- Footer Actions -->
      <div v-if="!hideFooter" class="footer-actions">
        <span>Show</span>
        <b-form-select
          style="width: 70px"
          v-model="pageLimit"
          :options="pages"
          @change="(v) => table.page.len(v).draw()"
          size="sm"
        />
        <span> per page</span>
      </div>
    </div>
  </b-card>
</template>

<script>
import HeaderActions from "./header.vue";
import { BCard } from "bootstrap-vue";
import $ from "jquery";
import "datatables.net";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-responsive/js/dataTables.responsive.js";
import "datatables.net-responsive-bs4/js/responsive.bootstrap4.js";
import "datatables.net-select-bs4";
import "datatables.net-select-bs4/css/select.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.css";
import JSZip from "jszip";
window.JSZip = JSZip;
import { BFormSelect } from "bootstrap-vue";
export default {
  props: [
    "compID",
    "setupTable",
    "hideFooter",
    "title",
    "selectBtn",
    "advancedFilter",
    "noSetupMounted",
    "notExpandAll",
    "customClass",
    "allowRefresh",
  ],
  data: () => ({
    table: null,
    collapse: false,
    pageLimit: 50,
    pages: [50, 100, 250, 500, 1000, 5000],
    otherConfig: null,
  }),
  created() {
    // Create elegant custom config
    this.otherConfig = { ...this.otherConfig, select: this.selectBtn };
  },
  mounted() {
    $.fn.dataTable.ext.errMode = "none";
    this.$root.$on("refresh-datatable", this.refreshAsNew);
    if (this.noSetupMounted) return;
    this.setup({ otherConfig: this.otherConfig });
    this.runQuery();
  },
  beforeDestroy() {
    this.$root.$off("refresh-datatable", this.refreshAsNew);
  },
  methods: {
    // run a query
    runQuery() {
      const { search, col } = this.$route.query;
      if (search) {
        if (!col) {
          this.table.search(search, true, false).draw();
        } else {
          const cols = col.split(",").map((c) => +c);
          this.table.columns(cols).search(search, true, false).draw();
        }
      }
    },
    // Setup table
    setup(config) {
      if (this.table) this.table.destroy();
      this.table = this.setupTable({
        ...config,
        otherConfig: this.otherConfig,
      });
      this.runQuery();
    },
    // Access any method in datatable.js
    access() {
      return this.table;
    },
    refresh() {
      this.table.ajax.reload();
    },
    refreshAsNew() {
      this.refresh();
      this.table.search("").columns().search("").draw();
    },
    // Actions
    doAction(elToClick) {
      // Copy
      $(`.${elToClick}`).click();
    },
    // Expand all
    expandAll() {
      this.collapse = !this.collapse;
      // Expand row details
      this.table
        .rows(this.collapse ? ":not(.parent)" : ".parent")
        .nodes()
        .to$()
        .find(".dtr-control")
        .trigger("click");
    },
    // Searching pure or in column
    searching(val, numColumn, byColumn = true) {
      const searchStr = val ? "^" + $.fn.dataTable.util.escapeRegex(val) + "$" : "";
      byColumn
        ? this.table.columns(numColumn).search(searchStr, true, false).draw()
        : this.table.search(searchStr, true, false).draw();
    },
  },
  components: {
    BCard,
    BFormSelect,
    HeaderActions,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-datatable.scss";
.left-actions {
  display: flex;
  min-width: 50%;
  align-items: center;
  > * {
    margin-right: 10px;
  }
}
</style>
