<template>
  <!-- Filter Modal -->
  <b-modal
    id="filter-modal"
    :title="`${data.label} Filter`"
    v-model="status"
    ok-title="Apply"
    @ok="apply"
    centered
  >
    <!-- Loading -->
    <div v-if="loading" class="text-center">
      <b-spinner style="width: 3rem; height: 3rem" />
    </div>
    <!-- Content Filters -->
    <div v-else-if="fields.length > 0">
      <b-form-group v-for="(f, i) in fields" :key="`fi${i}`" class="mb-2">
        <!-- in case Input -->
        <b-form-input
          v-if="f.type == 'number' || f.type == 'text'"
          :placeholder="f.label"
          v-model="form[f.key]"
        />
        <!-- in case Select -->
        <div v-else-if="f.type == 'select'">
          <h6 v-text="f.label" />
          <b-form-select
            v-model="form[f.key]"
            :options="f.options"
            text-field="text"
            value-field="value"
          />
        </div>
        <!-- In case period -->
        <div v-else-if="f.betweenPeriod">
          <h6>Date Period</h6>
          <div class="time-period">
            <!-- From -->
            <flat-pickr
              placeholder="Start date"
              v-model="form.date.from"
              class="form-control mr-1"
            />
            <!-- To -->
            <flat-pickr
              placeholder="End date"
              v-model="form.date.to"
              class="form-control"
            />
          </div>
        </div>
        <!-- In case textarea -->
        <b-form-textarea
          v-if="f.type == 'textarea'"
          :rows="f.rows"
          :placeholder="f.label"
          v-model="form[f.key]"
        />
      </b-form-group>
    </div>
    <!-- Filter -->
    <template #modal-footer>
      <div class="w-100 footer-f-modal">
        <b-button variant="primary" class="float-right" @click="apply">
          Apply
        </b-button>
        <b-button variant="danger" class="float-right" @click="reset">
          Reset
        </b-button>
        <b-button
          variant="secondary"
          class="float-right"
          @click="status = false"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
export default {
  props: ["data"],
  data: () => ({
    fields: [],
    loading: false,
    status: false,
    form: {
      date: {
        from: null,
        to: null,
      },
    },
  }),
  async mounted() {
    this.loading = true;
    this.fields = await Promise.all(
      this.data.fields.map(async (f) =>
        f.fetch ? { ...f, options: await f.fetch() } : f
      )
    );
    this.loading = false;
  },
  methods: {
    apply() {
      this.status = false;
      this.$emit("apply", this.form);
    },
    reset() {
      this.form.date.from = null;
      this.form.date.to = null;
      Object.keys(this.form).forEach((key) => {
        if (typeof this.form[key] == "string") this.form[key] = null;
      });
      this.$emit("reset", this.form);
      this.status = false;
    },
  },
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BFormTextarea,
    vSelect,
    // eslint-disable-next-line vue/no-unused-component
    ToastificationContent,
    BFormSelect,
    flatPickr,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.country-list {
  width: 100%;
}
.time-period {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.footer-f-modal {
  > * {
    margin-left: 10px;
  }
}
</style>