<template>
  <div id="addUser">
    <div class="text-right mb-2">
      <b-button @click="status = true" variant="success">Add Invoice</b-button>
    </div>
    <!-- Add Modal -->
    <b-modal
      v-model="status"
      id="modal-center"
      centered
      title="Add Invoice"
      ok-title="Add"
      @ok="add"
    >
      <!-- Loading -->
      <div v-if="loading" class="text-center">
        <b-spinner style="width: 3rem; height: 3rem" />
      </div>
      <!-- User Content -->
      <div v-else>
        <!-- Amount -->
        <b-form-group label="Amount" label-for="amount">
          <cleave
            id="number"
            v-model="form.amount"
            class="form-control"
            :raw="false"
            :options="{ numeral: true }"
            autocomplete="off"
            placeholder="101,000"
          />
        </b-form-group>

        <!-- textarea EXTRA -->
        Extra info/note
        <b-input-group label="Extra info/note" label-for="extra">
          <b-form-textarea
           v-model="form.extra" 
           />
        </b-input-group>

        <!-- Status -->
        <b-form-group label="Status">
          <b-form-checkbox-group
            @input="(v) => (form.status = [v[v.length - 1]])"
            v-model="form.status"
            :options="options"
            switches
            stacked
          />
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckboxGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Invoice from "@/services/invoice.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Cleave from "vue-cleave-component";

export default {
  data: () => ({
    loading: false,
    status: false,
    options: [
      { text: "Paid", value: "PAID" },
      { text: "Un-paid", value: "UNPAID" },
      { text: "Deduct", value: "DEDUCT" },
    ],
    form: {
      amount: null,
      extra: null,
      status: [],
    },
  }),
  methods: {
    async add() {
      this.loading = true;
      try {
        const form = {
          amount: +this.form.amount.replaceAll(",", ""),
          status: this.form.status[0] || null,
          user_id: +this.$route.params.user_id,
          extra: this.form.extra,
        };
        console.log(form);

        await Invoice.add(form);
        this.$emit("added");
      } catch (e) {
        console.log(e);
        const msg =
          e.response.status == 422
            ? Object.values(e.response.data.errors)[0][0]
            : "Something went wrong, please try again";
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Faild to add",
            icon: "XIcon",
            text: msg,
            variant: "danger",
          },
        });
      }
      this.loading = false;
    },
  },
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckboxGroup,
    // eslint-disable-next-line vue/no-unused-component
    ToastificationContent,
    Cleave,
    BFormTextarea,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.country-list {
  width: 100%;
}
</style>