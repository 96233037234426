<template>
  <div class="user-section mb-2">
    <b-spinner v-if="loading" variant="warning" />
    <div class="user-data" v-else-if="user">
      <feather-icon
        slot="button-content"
        icon="UserIcon"
        size="40"
        :class="`text-${isReseller ? 'warning' : 'info'} mr-2`"
        v-b-tooltip.hover.bottom
        :title="isReseller ? 'Reseller user' : 'Normal user'"
      />
      <div class="content-info">
        <h3 v-text="user.username"></h3>
        <p v-text="user.email" />
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import UserService from "@/services/user.service.js";
export default {
  props: ["userId"],
  data: () => ({
    loading: false,
    user: null,
  }),
  async mounted() {
    this.loading = true;
    this.user = await UserService.specific(this.userId);
    this.loading = false;
  },
  computed: {
    isReseller() {
      return this.user?.group == "RESELLER" || false;
    },
  },
  components: { VBTooltip },
  directives: { "b-tooltip": VBTooltip },
};
</script>

<style lang="scss" scoped>
.user-section {
  .user-data {
    display: flex;
    align-items: center;
  }
}
</style>