import api from "@axios";

class Invoice {
    // Add Invoice (for admin)
    static async add(data) {
        await api.post(`/invoices`, data)
    }
    // Update invoice to paid (for admin)
    static async toPaid(id) {
        const { data } = await api.put(`/invoices/${id}/to-paid`)
        return data
    }
}

export default Invoice;