import { extend } from 'jquery';

// supports CSV / COPY / EXCEL
function resultHTML(html) {
	if (typeof html != 'string') return html;
	const result = html.replaceAll('<br>', ' | ');
	const tmp = document.createElement('DIV');
	tmp.innerHTML = result;
	return tmp.textContent || tmp.innerText;
}
export default function exportBtns(colObj = {}) {
	let buttonCommon = {
		exportOptions: {
			columns: ':visible',
			format: {
				header(data, colIdx, trDOM, node) {
					const colName = colObj[colIdx] || trDOM.innerText;
					return colName;
				},
				body(data, colIdx, trDOM, node) {
                    console.log(trDOM == 1 && data);
					return resultHTML(data);
				}
			}
		}
	};
	return [
		extend(true, {}, buttonCommon, {
			extend: 'print'
		}),
		extend(true, {}, buttonCommon, {
			extend: 'copy'
		}),
		extend(true, {}, buttonCommon, {
			extend: 'excelHtml5',
			autoFilter: true
		}),
		extend(true, {}, buttonCommon, {
			extend: 'csv'
		}),
		extend(true, {}, buttonCommon, {
			extend: 'csv'
		})
	];
}
