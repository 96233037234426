// Status Options
export const statusOptions = [
	{ value: null, text: 'Status' },
	{ value: 'SUCCESS', text: 'Success' },
	{ value: 'PENDING', text: 'Pending' },
	{ value: 'PROCESS', text: 'Process' },
	{ value: 'FAILED', text: 'Failed' }
];
// Fast SVG icons export
export const fastIcons = {
	SUCCESS:
		'<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>',
	FAILED:
		'<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>',
	PENDING:
		'<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>',
	PROCESS:
		'<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg>'
};

// Map Status colors
export const statusColors = {
	SUCCESS: 'success',
	PROCESS: 'info',
	PENDING: 'warning',
	FAILED: 'danger'
};

// Map Status Invoices colors
export const statusInvoiceColors = {
	PAID: 'success',
	UNPAID: 'dark',
	DEDUCT: 'danger'
};

export const serverUrl = (path = '') => `${process.env.VUE_APP_SERVER_URL}${path}`;
