<template>
  <div id="orderHistory">
    <AddInvoice @added="refresh" />
    <!-- User section -->
    <UserSection :userId="userId" />
    <!-- Table View -->
    <VueDatatable ref="myTable" :setupTable="setupTable">
      <template v-slot:thead>
        <tr>
          <th class="id-col" style="width: 59px">
            <span>ID</span>
          </th>
          <th width="80px">Amount</th>
          <th width="100px">
            <b-form-select
              v-model="search.status"
              :options="statusOptions"
              @change="(v) => dataTable.searching(v, 2)"
              size="sm"
            />
          </th>
          <th>Created</th>
          <th>Order By</th>
        </tr>
      </template>
    </VueDatatable>
  </div>
</template>

<script>
import { BFormSelect } from "bootstrap-vue";
import AddInvoice from "./AddInvoice";
import VueDatatable from "@/components/util/vue-datatable/Index.vue";
import { setupTable, statusOptions } from "@/datatable/invoices.datatable.js";
import Invoice from "@/services/invoice.service.js";
import UserSection from "@/components/UI/UserSection.vue";

export default {
  name: "OrderHistroy",
  data: () => ({
    search: {
      status: null,
    },
    dataTable: {},
    setupTable,
    statusOptions,
  }),
  methods: {
    refresh() {
      this.dataTable.refresh();
    },
  },
  computed: {
    userId() {
      return this.$route.params.user_id;
    },
  },
  async mounted() {
    this.dataTable = this.$refs.myTable;
    this.dataTable.setup({
      url: `/api/invoices/for/${this.$route.params.user_id}`,
      forAdmin: true,
    });
    window.toPaid = async (invoiceId) => {
      const confirm = window.confirm("Are you sure to make it PAID");
      if (!confirm) return;
      await Invoice.toPaid(invoiceId);
      this.refresh();
    };
  },
  components: {
    BFormSelect,
    VueDatatable,
    AddInvoice,
    UserSection,
  },
};
</script>

<style>
</style>